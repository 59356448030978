<template>
  <b-container fluid>
    <b-overlay :show="loading">
        <b-table-simple border>
            <b-tr>
                <b-td>{{ $t('certify_form.remarks') }}</b-td>
                <b-td>{{ datas.proper_fillup_remarks }}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('globalTrans.status') }}</b-td>
                <b-td>
                    <b-badge variant="danger">{{ $i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত'  }}</b-badge>
                </b-td>
            </b-tr>
        </b-table-simple>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { getApplicationRemarks } from '../../api/routes'

export default {
  props: ['id'],
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
        this.loadData()
    }
  },
  data () {
    return {
      loading: false,
      datas: ''
    }
  },
  methods: {
    loadData () {
        this.loading = true
        RestApi.getData(agriResearchServiceBaseUrl, `${getApplicationRemarks}/${this.id}`).then(response => {
            if (response.success) {
                this.datas = response.data
                this.loading = false
            }
        })
    }
  }
}
</script>
