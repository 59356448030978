<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_sidebar.field_certification_application') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-link class="btn btn-primary" to="/research-farmer/seed-certification/field-certification-application" variant="primary">
              {{ $t('globalTrans.add_new') }}
            </b-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicationId)="data">
                      {{ $n(data.item.applicationId, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(application_date)="data">
                      <span class="capitalize">{{ getDate(data.item.created_at) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge class="badge badge-primary" v-if="data.item.application_status === 1">{{$t('certify_form.pending')}}</b-badge>
                      <b-badge class="badge badge-secondary" v-if="data.item.application_status === 2">{{$t('certify_form.verified')}}</b-badge>
                      <b-badge class="badge badge-dark" v-if="data.item.application_status === 3">{{$t('certify_form.inspection_level_1_done')}}</b-badge>
                      <b-badge class="badge badge-warning" v-if="data.item.application_status === 4">{{$t('certify_form.inspection_level_2_done')}}</b-badge>
                      <b-badge class="badge badge-info" v-if="data.item.application_status === 5">{{$t('certify_form.inspection_level_3_done')}}</b-badge>
                      <b-badge class="badge badge-success" v-if="data.item.application_status === 6">{{$t('globalTrans.approved')}}</b-badge>
                      <b-badge class="badge badge-danger" v-if="data.item.application_status === 7">{{$t('globalTrans.rejected')}}</b-badge>
                      <b-badge class="badge badge-success" v-if="data.item.application_status >= 8">{{$t('certify_form.certified')}}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- <b-button v-else variant=" iq-bg-success" size="sm" title="View" @click="viewApplication(data.item)"><i class="ri-eye-line"></i></b-button> -->
                        <!-- <b-badge class="cursor" v-if="data.item.application_status >= 8" v-b-modal.modal-4 variant=" iq-bg-success" size="sm" title="test" @click="view(data.item)"><i class="ri-eye-line"></i></b-badge> -->
                        <router-link v-if="data.item.application_status < 7 || data.item.application_status === 8" :to="'/research-farmer/seed-certification/field-certification-application?id='+data.item.general_info_id">
                            <b-badge class="cursor" title="Edit" variant=" iq-bg-success mr-1 mb-1" size="sm"><i class="ri-edit-line m-0"></i></b-badge>
                        </router-link>
                        <b-badge class="cursor" v-if="data.item.application_status === 3" title="Inspection view" v-b-modal.modal-3 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-clipboard-fill m-0"></i></b-badge>
                        <b-badge class="cursor" v-if="data.item.application_status === 7" title="Reject" v-b-modal.modal-1 variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="reject(data.item)"><i class="ri-eye-line m-0"></i></b-badge>
                        <b-badge class="cursor" title="View Application Details" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-badge>
                        <b-badge class="cursor" v-if="data.item.application_status >= 8" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" title="Certificate view" @click="view(data.item)"><i class="ri-file-text-line"></i></b-badge>
                        <b-badge class="cursor" v-if="data.item.payment_id" v-b-modal.modal-2 variant=" iq-bg-success mr-1" size="sm" title="Payment Recipt" @click="appPaymentReceipt(data.item)"><i class="ri-attachment-line m-1"></i></b-badge>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="lg" :title="$t('certify_form.app_rejected')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Reject-Component :id="appId" key="appId" />
    </b-modal>
    <b-modal id="modal-5" size="lg" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.print') }}
        </b-button>
      <Details :id="application" ref="details" />
    </b-modal>
    <b-modal id="modal-3" size="xl" :title="$t('certify_form.inspection_report_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <FormV :id="application" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-2" size="md" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <MoneyReceiptV :item="application" key="application"/>
    </b-modal>
    <b-modal id="modal-6" size="xl" :title="this.$t('research_seed.details_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <AllDetails :id="application" key="application" />
    </b-modal>
  </b-container>
</template>

<script>
import AllDetails from './Details.vue'
import FormV from './Application.vue'
import RejectComponent from './Reject.vue'
import Details from './DetailModal.vue'
import MoneyReceiptV from './MoneyReceipt.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getApplicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import moment from 'moment'
export default {
    components: {
        FormV,
        Details,
        MoneyReceiptV,
        RejectComponent,
        AllDetails
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                name: ''
            },
            appId: '',
            application: {},
            datas: [],
            info: {
                user_id: this.$store.state.Auth.authUser.id
            }
        }
    },
    computed: {
        formTitle () {
            return this.$t('externalUserIrrigation.money_receipt_title')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('globalTrans.applicant_name'), class: 'text-left' },
                { label: this.$t('certify_form.application_id'), class: 'text-left' },
                { label: this.$t('certify_form.application_date'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'applicant_org_name_bn' },
                    { key: 'applicant_name_bn' },
                    { key: 'applicationId' },
                    { key: 'application_date' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'applicant_org_name' },
                    { key: 'applicant_name' },
                    { key: 'applicationId' },
                    { key: 'application_date' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    created () {
        this.paymentStatusUpdate()
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
      reject (item) {
        this.appId = item.id
      },
      pdfExport () {
          this.$refs.details.pdfExport()
      },
      async searchData () {
          this.loadData()
      },
        appPaymentReceipt (item) {
            this.application = item
        },
        view (item) {
          this.application = item.general_info_id
        },
        getDate (date) {
            return moment(date).format('L')
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.info, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, getApplicationList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                  this.paginationData(response.data)
                  const allData = this.$store.state.list
                  this.datas = JSON.parse(JSON.stringify(allData))
              }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
          const listData = data.map(item => {
              const tmpOrg = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(newItem => newItem.value === item.govt_org_id)
              const newData = {
                  applicant_org_name: tmpOrg !== undefined ? tmpOrg.text_en : item.applicant_org_name,
                  applicant_org_name_bn: tmpOrg !== undefined ? tmpOrg.text_bn : item.applicant_org_name_bn
              }
              return Object.assign({}, item, newData)
          })
          return listData
        },
        async paymentStatusUpdate () {
            if (this.$route.params.status && this.$route.query.transId) {
                const status = this.$route.params.status
                const tranId = this.$route.query.transId
                this.$bvModal.show('modal-processing')
                await RestApi.getData(agriResearchServiceBaseUrl, '/application/payment/' + status, { transId: tranId }).then(response => {
                this.$bvModal.hide('modal-processing')
                if (response.success) {
                    this.$toast.success({
                    title: 'Success',
                    message: response.message,
                    color: '#D6E09B'
                    })
                    this.loadData()
                } else {
                    this.$toast.error({
                    title: 'Error',
                    message: response.message
                    })
                }
                })
                this.$router.push('/research-farmer/seed-certification/field-application-list')
            }
        }
    }
}
</script>
<style scoped>
  .cursor{
    cursor: pointer;
  }
</style>
