<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <!-- <template v-slot:headerAction>
            <router-link to="technology-data-list" :class="'btn btn-primary text-light mr-2'">{{ $t('n_c_data_bank.technology_data') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          </template> -->
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.inspection_report_level_1') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col lg="12" sm="12">
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('research_seed.inspection_date') }}</b-th>
                                <b-td style="width:25%">
                                    {{ technologyData.inspection_date | dateFormat }}
                                </b-td>
                                <b-th style="width:25%">{{ $t('research_seed.representative_present') }}</b-th>
                                <b-td style="width:25%">
                                    <slot v-if="technologyData.inspection_reports != undefined">{{ technologyData.inspection_reports.representative_present === '0' ? $t('globalTrans.yes') : $t('globalTrans.no') }}</slot>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.verification_seed_source') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col lg="12" sm="12">
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('certify_form.amount_of_land') }}</b-th>
                                <b-td style="width:25%">
                                    {{ $n(technologyData.amount_of_land) }}
                                </b-td>
                                <b-th style="width:25%">{{ $t('research_seed.hectors_of_field') }}</b-th>
                                <b-td style="width:25%">
                                    {{ $n(technologyData.hectors_of_field) }}
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('research_seed.ensuring_seed_quality') }}</b-th>
                                <b-td style="width:25%">
                                    <slot v-if="technologyData.ensuring_seed_quality == 0">{{ $t('globalTrans.yes') }}</slot>
                                    <slot v-if="technologyData.ensuring_seed_quality == 1">{{ $t('globalTrans.no') }}</slot>
                                </b-td>
                                <b-th style="width:25%">{{ $t('research_seed.other_file') }}</b-th>
                                <b-td style="width:25%">
                                    <b v-if="technologyData.others_file">
                                        (<a target = '_blank' :href="agriResearchServiceBaseUrl + 'storage/uploads/inspection/original/' + technologyData.others_file" download>{{$t('globalTrans.view_download')}}</a> )
                                    </b>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('research_seed.seed_production_file') }}</b-th>
                                <b-td style="width:25%">
                                    <b v-if="technologyData.seed_production_file">
                                        (<a target = '_blank' :href="agriResearchServiceBaseUrl + 'storage/uploads/inspection/original/' + technologyData.seed_production_file" download>{{$t('globalTrans.view_download')}}</a> )
                                    </b>
                                </b-td>
                                <b-th style="width:25%">{{ $t('research_seed.importing_seed_file') }}</b-th>
                                <b-td style="width:25%">
                                    <b v-if="technologyData.importing_seed_file">
                                        (<a target = '_blank' :href="agriResearchServiceBaseUrl + 'storage/uploads/inspection/original/' + technologyData.importing_seed_file" download>{{$t('globalTrans.view_download')}}</a> )
                                    </b>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('research_seed.purchase_seed_file') }}</b-th>
                                <b-td style="width:25%">
                                    <b v-if="technologyData.purchase_seed_file">
                                        (<a target = '_blank' :href="agriResearchServiceBaseUrl + 'storage/uploads/inspection/original/' + technologyData.purchase_seed_file" download>{{$t('globalTrans.view_download')}}</a> )
                                    </b>
                                </b-td>
                                <b-th style="width:25%">{{ $t('research_seed.separation_distance') }}</b-th>
                                <b-td style="width:25%">
                                    {{ $i18n.locale === 'bn' ? technologyData.separation_distance : technologyData.separation_distance }}
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:25%">{{ $t('certify_form.unit_measurement') }}</b-th>
                                <b-td style="width:25%">
                                    {{ $i18n.locale === 'bn' ? technologyData.separationUnit_name_bn : technologyData.separationUnit_name }}
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.inspection_officer_selection') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row id="form" class="mt-2">
                    <div class="col-md-12">
                        <div class="col-md-12">
                            <b-table bordered hover :items="technologyData.first_officer" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + 1) }}
                                </template>
                                <template v-slot:cell(organization)="data">
                                    {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_en : $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_bn }}
                                </template>
                                <template v-slot:cell(employee_designation)="data">
                                    {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                                </template>
                                <template v-slot:cell(employee_name)="data">
                                    {{ ($i18n.locale=='en') ? $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-row>
                <b-row v-if="technologyData2">
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.inspection_report_level_2') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="technologyData2">
                    <b-col lg="12" sm="12">
                            <b-row class="mt-2">
                                <b-col lg="12" sm="12">
                                    <b-table-simple bordered>
                                        <b-tr>
                                            <b-th style="width:25%">{{ $t('research_seed.separation_distance') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ $n(technologyData2.second_separation_distance) }}
                                            </b-td>
                                            <b-th style="width:25%">{{ $t('certify_form.unit_measurement') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ $i18n.locale === 'bn' ? technologyData2.hectorUnit_name_bn : technologyData2.hectorUnit_name }}
                                            </b-td>
                                        </b-tr>
                                        <b-tr v-if="technologyData2.accepted_land > 0">
                                            <b-th style="width:25%">{{ $t('research_seed.amount_of_land_acquired') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ $n(technologyData2.accepted_land) }}
                                            </b-td>
                                            <b-th style="width:25%">{{ $t('research_seed.amount_of_cancelled_land') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ $n(technologyData2.rejected_land) }}
                                            </b-td>
                                        </b-tr>
                                        <b-tr v-if="technologyData2.second_seed_yeild > 0">
                                            <b-th style="width:25%">{{ $t('research_seed.possible_seed_yield') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ $n(technologyData2.second_seed_yeild) }}
                                            </b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" sm="12">
                                <template>
                                        <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center"> {{ $t('research_seed.number_of_trees') }}</h5>
                                        </div>
                                    </template>
                                </b-col>
                            </b-row>
                            <b-row id="form" class="mt-2">
                                <div class="col-md-12">
                                    <b-table-simple bordered>
                                        <b-tr v-for="(item, index) in technologyData2.number_of_trees" :key="index">
                                            <b-th style="width:25%">{{ $t('research_seed.crop_condition') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ item.second_tree_sheaves }}
                                            </b-td>
                                            <b-th style="width:25%">{{ $t('certify_form.amount') }}</b-th>
                                            <b-td style="width:25%">
                                                {{ item.amount }}
                                            </b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </div>
                            </b-row>
                            <b-row>
                                <b-col lg="12" sm="12">
                                <template>
                                        <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center"> {{ $t('research_seed.inspection_officer_selection') }}</h5>
                                        </div>
                                    </template>
                                </b-col>
                            </b-row>
                            <b-row id="form" class="mt-2">
                                <div class="col-md-12">
                                    <div class="col-md-12">
                                        <b-table bordered hover :items="technologyData2.second_officer" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(organization)="data">
                                            {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_en : $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_bn }}
                                        </template>
                                        <template v-slot:cell(employee_designation)="data">
                                            {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                                        </template>
                                        <!-- <template v-slot:cell(employee_name)="data">
                                            {{ ($i18n.locale=='en') ? $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                                        </template> -->
                                    </b-table>
                                    </div>
                                </div>
                            </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="technologyData3">
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.inspection_report_level_3') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="technologyData3">
                    <b-col lg="12" sm="12">
                        <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:25%">{{ $t('research_seed.inspection_date') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ technologyData3.inspection_date | dateFormat }}
                                    </b-td>
                                    <b-th style="width:25%">{{ $t('research_seed.representative_present') }}</b-th>
                                    <b-td style="width:25%">
                                        <slot v-if="technologyData3.inspection_reports != undefined">{{ technologyData3.inspection_reports.representative_present === '0' ? $t('globalTrans.yes') : $t('globalTrans.no') }}</slot>
                                    </b-td>
                                </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="technologyData3">
                    <b-col lg="12" sm="12">
                        <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:25%">{{ $t('research_seed.amount_of_land_acquired') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $n(technologyData3.third_land_acquired) }}
                                    </b-td>
                                    <b-th style="width:25%">{{ $t('research_seed.amount_of_cancelled_land') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $n(technologyData3.third_land_cancelled) }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:25%">{{ $t('research_seed.possible_seed_yield') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $n(technologyData3.third_seed_yield) }}
                                    </b-td>
                                    <b-th style="width:25%">{{ $t('research_seed.mixture_varieties') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $i18n.locale === 'bn' ? technologyData3.third_mixture_varieties_bn : technologyData3.third_mixture_varieties }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:25%">{{ $t('research_seed.other_variety_type') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $i18n.locale === 'bn' ? technologyData3.third_variety_type_bn : technologyData3.third_variety_type }}
                                    </b-td>
                                    <b-th style="width:25%">{{ $t('research_seed.offensive_weeds') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $i18n.locale === 'bn' ? technologyData3.third_offensive_weeds_bn : technologyData3.third_offensive_weeds }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:25%">{{ $t('research_seed.diseased_trees') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $i18n.locale === 'bn' ? technologyData3.third_diseased_trees_bn : technologyData3.third_diseased_trees }}
                                    </b-td>
                                    <b-th style="width:25%">{{ $t('research_seed.crop_condition') }}</b-th>
                                    <b-td style="width:25%">
                                        {{ $i18n.locale === 'bn' ? technologyData3.third_crop_condition_bn : technologyData3.third_crop_condition }}
                                    </b-td>
                                </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row v-if="technologyData3">
                    <b-col lg="12" sm="12">
                    <template>
                            <div style="font-size:12px; background-color: #337982; padding:6px">
                                <h5 class="text-white text-center"> {{ $t('research_seed.inspection_officer_selection') }}</h5>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row id="form" class="mt-2" v-if="technologyData3">
                    <div class="col-md-12">
                        <div class="col-md-12">
                            <b-table bordered hover :items="technologyData3.third_officer" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                            <template v-slot:cell(index)="data">
                                {{ $n(data.index + 1) }}
                            </template>
                            <template v-slot:cell(organization)="data">
                                {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_en : $store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.item.organization)).text_bn }}
                            </template>
                            <template v-slot:cell(employee_designation)="data">
                                {{ ($i18n.locale=='en') ? $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                            </template>
                            <!-- <template v-slot:cell(employee_name)="data">
                                {{ ($i18n.locale=='en') ? $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_en : $store.state.commonObj.designationList.find(doc => doc.value === parseInt(data.item.employee_designation)).text_bn }}
                            </template> -->
                        </b-table>
                        </div>
                    </div>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { inspectionOneShow, getUserList } from '../../api/routes'
// import ExportPdf from './export_pdf_details'

export default {
    components: {
    },
    props: ['id'],
    created () {
      this.getUserListData()
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
        loading: false,
        technologyData: [],
        technologyData2: {},
        technologyData3: {},
        employeeList: [],
        agriResearchServiceBaseUrl: agriResearchServiceBaseUrl
    }
  },
  computed: {
    // loading () {
    //   return this.$store.state.commonObj.loading
    // }
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('globalTrans.designation'), class: 'text-left' },
          { label: this.$t('research_seed.employee'), class: 'text-left' },
          { label: this.$t('research_seed.remarks'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'organization' },
          { key: 'employee_designation' },
          { key: 'employee_text_bn' },
          { key: 'remarks' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'organization' },
          { key: 'employee_designation' },
          { key: 'employee_text' },
          { key: 'remarks' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    // pdfExport () {
    //   const reportTitle = this.$t('n_c_data_bank.technologyDataDetails')
    //   ExportPdf.exportPdfDetails(reportTitle, this.technologyData, this, this.technologyData.pdf_image)
    // },
    async getUserListData () {
         this.loading = true
        const result = await RestApi.getData(authServiceBaseUrl, getUserList, [])
        if (result.success) {
            this.employeeList = result.data
            if (this.employeeList.length > 0) {
              if (this.id) {
                this.getTechnologyDataAllInfo()
              }
            } else {
            }
        } else {
        }
    },
    async getTechnologyDataAllInfo () {
        this.loading = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${inspectionOneShow}/${this.id}`)
        if (result.success) {
            this.technologyData = result.data
            this.technologyData2 = result.secondIns
            this.technologyData3 = result.thirdIns
            const qualityUnitObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(Item => Item.value === parseInt(this.technologyData.separation_unit_id))
            if (qualityUnitObj !== undefined) {
                this.technologyData.hectorUnit_name = qualityUnitObj.text_en
                this.technologyData.hectorUnit_name_bn = qualityUnitObj.text_bn
            } else {
                this.technologyData.hectorUnit_name = ''
                this.technologyData.hectorUnit_name_bn = ''
            }
            const separationUnitObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(Item => Item.value === parseInt(this.technologyData.separation_unit_id))
            if (separationUnitObj !== undefined) {
                this.technologyData.separationUnit_name = separationUnitObj.text_en
                this.technologyData.separationUnit_name_bn = separationUnitObj.text_bn
            } else {
                this.technologyData.separationUnit_name = ''
                this.technologyData.separationUnit_name_bn = ''
            }
            const listData = result.data.first_officer.map(item => {
                const employeeObj = this.employeeList.find(employeeList => employeeList.value === parseInt(item.employee_name))
                const employeeData = {}
                if (employeeObj !== undefined) {
                    employeeData.employee_text = employeeObj.text_en
                    employeeData.employee_text_bn = employeeObj.text_bn
                } else {
                    employeeData.employee_text = ''
                    employeeData.employee_text_bn = ''
                }
                return Object.assign({}, item, employeeData)
            })
            this.technologyData.first_officer = listData
            if (this.technologyData2 !== null && result.secondIns) {
                const qualityUnitObj2 = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(Item => Item.value === parseInt(this.technologyData2.second_separation_unit_id))
                if (qualityUnitObj2 !== undefined) {
                    this.technologyData2.hectorUnit_name = qualityUnitObj2.text_en
                    this.technologyData2.hectorUnit_name_bn = qualityUnitObj2.text_bn
                }
                const listData2 = this.technologyData2.second_officer.map(item => {
                    const employeeObj = this.employeeList.find(employeeList => employeeList.value === parseInt(item.employee_name))
                    const employeeData = {}
                    if (employeeObj !== undefined) {
                        employeeData.employee_text = employeeObj.text_en
                        employeeData.employee_text_bn = employeeObj.text_bn
                    } else {
                        employeeData.employee_text = ''
                        employeeData.employee_text_bn = ''
                    }
                    return Object.assign({}, item, employeeData)
                })
                this.technologyData2.second_officer = listData2
            }
            if (this.technologyData3 !== null && result.thirdIns !== null) {
                const qualityUnitObj3 = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(item => item.value === parseInt(this.technologyData3.third_land_acquired_unit_id))
                if (qualityUnitObj3 !== undefined) {
                    this.technologyData3.hectorUnit_name = qualityUnitObj3.text_en
                    this.technologyData3.hectorUnit_name_bn = qualityUnitObj3.text_bn
                } else {
                    this.technologyData3.hectorUnit_name = ''
                    this.technologyData3.hectorUnit_name_bn = ''
                }
                const cancelledUnitObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(item => item.value === parseInt(this.technologyData3.third_land_cancelled_unit_id))
                if (cancelledUnitObj !== undefined) {
                    this.technologyData3.cancelledUnit_name = cancelledUnitObj.text_en
                    this.technologyData3.cancelledUnit_name_bn = cancelledUnitObj.text_bn
                } else {
                    this.technologyData3.cancelledUnit_name = ''
                    this.technologyData3.cancelledUnit_name_bn = ''
                }
                const yieldUnitObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(item => item.value === parseInt(this.technologyData3.third_seed_yield_unit_id))
                if (yieldUnitObj !== undefined) {
                    this.technologyData3.yieldUnit_name = yieldUnitObj.text_en
                    this.technologyData3.yieldUnit_name_bn = yieldUnitObj.text_bn
                } else {
                    this.technologyData3.yieldUnit_name = ''
                    this.technologyData3.yieldUnit_name_bn = ''
                }
                const listData3 = this.technologyData3.third_officer.map(item => {
                    const employeeObj = this.employeeList.find(employeeList => employeeList.value === parseInt(item.employee_name))
                    const employeeData = {}
                    if (employeeObj !== undefined) {
                        employeeData.employee_text = employeeObj.text_en
                        employeeData.employee_text_bn = employeeObj.text_bn
                    } else {
                        employeeData.employee_text = ''
                        employeeData.employee_text_bn = ''
                    }
                    return Object.assign({}, item, employeeData)
                })
                this.technologyData3.third_officer = listData3
            }
            this.loading = false
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
