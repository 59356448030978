import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, thisObject, detail, firstInspection, secondInspection, thirdInspection) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        { text: reportTitle, style: 'header2', alignment: 'center' }
      ]
      const topRows = [
        [
          { text: thisObject.$t('certify_form.application_number'), style: 'search', bold: true },
          { text: ':', style: 'search', alignment: 'center', bold: true },
          { text: thisObject.$n(detail.applicationId, { useGrouping: false }), alignment: 'left', style: 'search', bold: true },
          { text: thisObject.$t('globalTrans.date'), alignment: 'right', style: 'search', bold: true },
          { text: ':', style: 'search', alignment: 'center', bold: true },
          { text: dateFormat(detail.created_at), alignment: 'left', style: 'search', bold: true }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['32%', '5%', '20%', '20%', '5%', '20%'],
          body: topRows
        },
        layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const bodyRows = [
        [
          { text: thisObject.$t('certify_form.dealer_registration_number'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: thisObject.$n(thisObject.registration_number.dealer_registration_number, { useGrouping: false }), alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('testing_and_tag_issue.applicant_name'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? detail.applicantaddresstwo.applicant_name_bn : detail.applicantaddresstwo.applicant_name, alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('certify_form.plot_location'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: detail.plotlocationtwo !== undefined ? thisObject.getScheme(detail.plotlocationtwo.scheme_id) : '', alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('research_seed.seed_name'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? detail.seedsourcetwo.seedname.seed_name_bn : detail.seedsourcetwo.seedname.seed_name, alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('external_research.seed_variety'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? detail.seedsourcetwo.seedvariety.seed_variety_name_bn : detail.seedsourcetwo.seedvariety.seed_variety_name, alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('external_research.seed_class'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? detail.seedsourcetwo.seedclass.seed_class_name_bn : detail.seedsourcetwo.seedclass.seed_class_name, alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('certify_form.possible_date_harvesting'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: dateFormat(detail.flowers.possible_date_harvesting), alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('certify_form.separation_distance'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: thisObject.$n(thisObject.firstInspection.separation_distance), alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('research_seed.is_final_report'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: thisObject.$t('globalTrans.yes'), alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('research_seed.possible_seed_yield'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: thisObject.$n(thisObject.thirdInspection.third_land_acquired || thisObject.secondInspection.accepted_land), alignment: 'left', style: 'search' }
        ],
          [
            { text: thisObject.$t('research_seed.representative_present'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.inspection_report.representative_present === '0' ? thisObject.$t('globalTrans.yes') : thisObject.$t('globalTrans.no'), alignment: 'left', style: 'search' }
          ],
        [
          { text: thisObject.$t('certify_form.is_this_seed_ensuring_field_inspection'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: firstInspection.ensuring_seed_quality === '0' ? thisObject.$t('globalTrans.yes') : thisObject.$t('globalTrans.no'), alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('research_seed.hectors_of_field'), style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: firstInspection ? thisObject.$n(firstInspection.hectors_of_field) : '', alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('globalTrans.date') + ':', style: 'search' },
          { text: '', style: 'search', alignment: 'center' },
          { text: '', alignment: 'left', style: 'search' }
        ],
        [
          { text: thisObject.$t('application_details.signature') + ':', style: 'signature' },
          { text: '', style: 'search', alignment: 'center' },
          { text: '', alignment: 'left', style: 'search' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['30%', '5%', '20%'],
          body: bodyRows
        },
        layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
      })
      if (secondInspection.number_of_trees.length > 0) {
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        // pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        let allRows = []
        allRows = [
          [
            { text: thisObject.$t('research_seed.sl_number'), style: 'th', alignment: 'center' },
            { text: thisObject.$t('research_seed.second_tree_sheaves'), style: 'th', alignment: 'center' },
            { text: thisObject.$t('research_seed.amount'), style: 'th', alignment: 'center' }
          ]
        ]
        // table body
        secondInspection.number_of_trees.filter((data, optionIndex) => {
          const rowItem = [
            { text: thisObject.$n(data.second_tree_counts, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: thisObject.getNameData(data.second_tree_sheaves), style: 'td', alignment: 'center' },
            { text: thisObject.$n(data.amount, { useGrouping: false }), style: 'td', alignment: 'center' }
          ]
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '40%', '40%'],
            body: allRows
          }
        })
      }
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        background: function (currentPage, pageSize) {
          return [
              {
                  canvas: [
                      { type: 'line', x1: 5, y1: 5, x2: 590, y2: 5, lineWidth: 1 },
                      { type: 'line', x1: 5, y1: 5, x2: 5, y2: 835, lineWidth: 1 },
                      { type: 'line', x1: 5, y1: 835, x2: 590, y2: 835, lineWidth: 1 },
                      { type: 'line', x1: 590, y1: 5, x2: 590, y2: 835, lineWidth: 1 }
                  ]
              }
          ]
      },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          th2: {
            fillColor: '#dee2e6',
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fillColor: '#dee2e6',
            fontSize: 16,
            bold: 'Courier-Bold',
            bolditalics: 'Courier-BoldOblique',
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
